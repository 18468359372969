import { createColumnHelper } from "@tanstack/react-table";
import { motion } from "framer-motion";
import React from "react";
import FloatLabel from "../FloatingLabel";
import { Button, FloatingLabelInput, Heading, Img } from "./components";
import { ReactTable } from "./components/ReactTable";
import UserProfileSelection from "./components/UserProfileSelection";
import FooterSection from "./FooterSection";
import WelcomeSection from "./WelcomeSection";

const tableData = [
  {
    themeHeader: "Immobilien",
    linkHeader: "Jeder der Eigentum kaufen, bauen oder modernisieren möchte...",
    prizeHeader: "50€",
    validityHeader: "∞",
    actionHeader: "Jetzt empfehlen",
    homeImage: "/images3/img_home_2.svg",
  },
  {
    homeImage: "/images3/img_vector.svg",
    themeHeader: "Finanzberater",
    linkHeader: "Wer endlich clever sparen und/oder Geld anlegen möchte...\n\n",
    prizeHeader: "100€",
    validityHeader: "∞",
    actionHeader: "Jetzt empfehlen",
  },
  {
    homeImage: "/images3/img_shield_check_3.svg",
    themeHeader: "Versicherung",
    shieldCheckImageDuplicate: "/images3/img_shield_check_3.svg",
    insuranceTextDuplicate: "Versicherung",
    arrowLeftShieldImageDuplicate: "/images3/img_arrow_left.svg",
    linkHeader:
      "Wer unzufrieden mit dem Service, der Leistung oder den Beiträgen seiner Versicherungen ist...",
    loremIpsumText: "Qorem ipsum dolor sit amet, consectetur adipiscing elit.",
    inboxLoremImage: "/images3/img_inbox.svg",
    prizeHeader: "50€",
    priceTextSecond: "50€",
    validityHeader: "∞",
    validityTextSecond: "∞",
    actionHeader: "Jetzt empfehlen",
    recommendButtonSecond: "Jetzt empfehlen",
  },
];

export default function EmpfehlungsportalPage() {
  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper();
    return [
      tableColumnHelper.accessor("themeHeader", {
        cell: (info) => (
          <div className="flex flex-wrap items-center mb-3 gap-1.5">
            <Img
              src={info.row.original.homeImage}
              alt="Home Image"
              className="h-[16px] w-[16px]"
            />
            <Heading
              as="p"
              className="text-[14px] font-medium text-[#000000]-900"
            >
              {info.getValue()}
            </Heading>
            <Img
              src="/images3/img_arrow_left.svg"
              alt="Arrow Image"
              className="h-[10px] w-[10px]"
            />
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="h3"
              className="text-[14px] font-medium text-blue_gray-400"
            >
              Thema
            </Heading>
          </div>
        ),
        meta: { width: "166px" },
      }),
      tableColumnHelper.accessor("linkHeader", {
        cell: (info) => (
          <div className="flex flex-1 items-center mb-3 justify-start mr-4   gap-[18px] ">
            <Heading
              as="p"
              className="self-center text-[14px] font-medium text-[#000000]-900 whitespace-nowrap max-w-[292px] truncate"
            >
              {info.getValue()}
            </Heading>
            <Img
              src="/images3/img_inbox.svg"
              alt="Inbox Image"
              className="h-[14px] w-[14px]"
            />
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="h4"
              className="text-[14px] font-medium text-blue_gray-400"
            >
              Link passend für
            </Heading>
          </div>
        ),
        meta: { width: "376px" },
      }),
      tableColumnHelper.accessor("prizeHeader", {
        cell: (info) => (
          <div className="flex mb-3">
            <Heading
              as="p"
              className="text-[14px] font-medium text-[#000000]-900"
            >
              {info.getValue()}
            </Heading>
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="h5"
              className="text-[14px] font-medium mb-3 text-blue_gray-400"
            >
              Deine Prämie
            </Heading>
          </div>
        ),
        meta: { width: "124px" },
      }),
      tableColumnHelper.accessor("validityHeader", {
        cell: (info) => (
          <div className="flex self-end mb-3">
            <Heading
              as="p"
              className="text-[14px] font-medium text-[#000000]-900"
            >
              {info.getValue()}
            </Heading>
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="h6"
              className="text-[14px] font-medium text-blue_gray-400 mb-3"
            >
              Gültig
            </Heading>
          </div>
        ),
        meta: { width: "76px" },
      }),
      tableColumnHelper.accessor("actionHeader", {
        cell: (info) => (
          <div className="flex mb-3">
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <a href="#" target="_blank" className="relative">
                <Button
                  shape="round"
                  className="min-w-[114px] rounded-[5px] px-2.5 py-1 font-semibold"
                >
                  {info.getValue()}
                </Button>
                <motion.div
                  className="absolute inset-0 bg-white opacity-20"
                  initial={{ x: "-100%" }}
                  animate={{ x: "100%" }}
                  transition={{
                    repeat: Infinity,
                    duration: 1.5,
                    repeatDelay: 4,
                    ease: "easeInOut",
                  }}
                />
              </a>
            </motion.div>
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="p"
              className="text-[14px] font-medium mb-3 text-blue_gray-400"
            >
              Aktion
            </Heading>
          </div>
        ),
        meta: { width: "114px" },
      }),
    ];
  }, []);

  return (
    <>
      <div className="flex w-full flex-col items-center bg-[#ffffff] py-5">
        {/* welcome section */}
        <WelcomeSection />
        <div className="container-xs mt-2.5 mdx:px-5">
          <div className="rounded-[14px] bg-[#fafafa] p-2.5">
            <div className="flex gap-2.5 mdx:flex-col">
              <div className="flex-1 mdx:self-stretch">
                <div className="flex flex-col gap-[18px] rounded-[10px] border border-solid border-blue_gray-100 bg-[#ffffff] p-2.5 shadow-xs">
                  <div className="mx-5 mt-3.5 flex flex-col gap-5 mdx:mx-0">
                    <div className="flex flex-col items-start gap-[18px]">
                      <Heading
                        size="headingxs"
                        as="h2"
                        className="text-[16px] font-semibold text-[#000000]-900"
                      >
                        Wähle den passenden Link aus
                      </Heading>
                      <ReactTable
                        bodyProps={{ className: "" }}
                        className="self-stretch smx:block smx:overflow-x-auto smx:whitespace-nowrap"
                        columns={tableColumns}
                        data={tableData}
                      />
                    </div>
                    <div className="h-px bg-blue_gray-100" />
                  </div>
                  <div className="mx-5 flex mdx:mx-0 mdx:flex-col">
                    <UserProfileSelection />
                    <Img
                      src="/images3/img_zeichenfla_che_2_4x.png"
                      alt="List Image"
                      className="mb-6 mt-[120px] mx-[-30px] h-[40px] w-[8%] object-contain mdx:w-full"
                      style={{ zIndex: 9999 }}
                    />
                    <UserProfileSelection
                      headingText="02 Klicken"
                      descriptionText="Klicke auf den “Jetzt empfehlen” Button, Nachrichtentext prüfen und ggf. anpassen."
                      userImage="/images3/excl export.png"
                    />
                    <Img
                      src="/images3/img_zeichenfla_che_2_4x_62x62.png"
                      alt="Zeichenflache"
                      className="mb-[110px] mx-[-20px] mt-3 h-[62px] w-[6%] object-contain mdx:w-full"
                      style={{ zIndex: 9999 }}
                    />
                    <UserProfileSelection
                      headingText="03 Versenden"
                      descriptionText="Jetzt versenden klicken und dein WhatsApp öffnet sich. Dort einfach die gewünschten Empfehlungen auswählen und versenden."
                      userImage="/images3/img_volume.svg"
                      className="gap-1"
                    />
                    <Img
                      src="/images3/img_zeichenfla_che_2_4x_28x66.png"
                      alt="Zeichenflache"
                      className="mb-[124px] mx-[-25px] mt-[34px] h-[28px] w-[8%] object-contain mdx:w-full"
                      style={{ zIndex: 9999 }}
                    />
                    <UserProfileSelection
                      headingText="04 Nochmal"
                      descriptionText="Wiederhole die Schritte 1-3 um weitere Links zu versenden. So kannst du deinen Bekannten immer den richtigen Link für Ihre aktuelle Situation senden."
                      userImage="/images3/img_arrows_repeat_1.svg"
                      className="gap-1"
                    />
                  </div>
                  <div className="flex justify-between gap-2">
                    <a href="#" className="flex-1">
                      <Button
                        color="light_blue_A700"
                        shape="round"
                        className="w-full rounded-[5px] font-semibold py-1"
                      >
                        Erklärfilm ansehen
                      </Button>
                    </a>
                    <a href="#" className="flex-1">
                      <Button
                        color="blue_gray_900"
                        shape="round"
                        className="w-full rounded-[5px] font-semibold py-1"
                      >
                        Als App speichern
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="h-[544px] w-[32%] rounded-[10px] bg-cover bg-no-repeat px-2.5 py-3 mdx:h-auto mdx:w-full"
                style={{
                  backgroundImage: "url(/images3/img_group_69.svg)",
                }}
              >
                <div className="mt-1.5 flex flex-col gap-6">
                  <div className="mx-5 flex flex-col gap-[42px] mdx:mx-0">
                    <div className="flex flex-col gap-2.5">
                      <div className="flex items-start justify-between gap-5 px-[52px] mdx:px-5">
                        <Img
                          src="/images3/img_user_blue_gray_900.svg"
                          alt="User Image"
                          className="mt-3 h-[52px] w-[22%] object-contain"
                        />
                        <div className="flex flex-col items-start self-center">
                          <Heading
                            size="headingxs"
                            as="h6"
                            className="text-[16px] font-semibold text-[#000000]-900"
                          >
                            Guthaben:
                          </Heading>
                          <Heading
                            size="headingmd"
                            as="h1"
                            className="text-[42px] font-bold text-light_blue-a700 mdx:text-[38px] smx:text-[32px]"
                          >
                            250,00€
                          </Heading>
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-2.5">
                        <div className="flex flex-col items-start gap-0.5 self-stretch">
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Morflax Studio Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 self-end text-[14px] font-medium text-[#000000]-900"
                            >
                              Betrag hier untern eingeben
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Redeem Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 text-[14px] font-medium text-[#000000]-900"
                            >
                              Einlösen klicken
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Voucher Code Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 self-end text-[14px] font-medium text-[#000000]-900"
                            >
                              Innerhalb ca. 7 Tagen Gutscheincode erhalten
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Redeem Instruction Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 flex self-end text-[14px] font-medium text-[#000000]-900"
                            >
                              <span className="text-[#000000]-900">
                                Code auf&nbsp;
                              </span>
                              <a
                                href="#"
                                className="inline text-light_blue-a700 underline"
                              >
                                www.einloesen.de
                              </a>{" "}
                              eingeben
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Product Selection Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 text-[14px] font-medium text-[#000000]-900"
                            >
                              Gutschein oder Produkt aussuchen
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Receive Product Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 self-end text-[14px] font-medium text-[#000000]-900"
                            >
                              Wunschprodukt per Post oder E-Mail erhalten
                            </Heading>
                          </div>
                        </div>
                        <Heading
                          as="p"
                          className="text-[14px] font-medium text-cyan-a400"
                        >
                          Auswahl aus über 700 Gutscheinen und Sachprämien!
                        </Heading>
                      </div>
                    </div>
                    <div
                      className="mx-[18px] h-[2px] mdx:mx-0"
                      style={{
                        background:
                          "repeating-linear-gradient(to right, #94A3B8 0, #94A3B8 12px, transparent 12px, transparent 16px)",
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-2.5">
                    <FloatLabel label={"Anzahl in Euro"}>
                      <input
                        required
                        name="Amount Input Field"
                        type="text"
                        defaultValue="5-200"
                        placeholder={`Anzahl in Euro`}
                        className="flex h-[74px] items-center justify-center rounded-[10px] border-[1.5px] border-solid border-blue_gray-100 px-5 text-[16px] font-semibold text-blue_gray-400 w-full"
                      />
                    </FloatLabel>

                    <Button
                      size="xl"
                      className="self-stretch rounded-[10px] px-[34px] font-semibold !text-[#ffffff] smx:px-5"
                      style={{
                        backgroundImage: "url(/images3/mesh.png)",
                      }}
                    >
                      EINLÖSEN
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer section */}
        <FooterSection />
      </div>
    </>
  );
}
