import { motion } from "framer-motion";
import React from "react";
import { Button, Heading, Img, Text } from "./components";

export default function WelcomeSection() {
  return (
    <>
      {/* welcome section */}
      <div className="mt-1.5 flex flex-col items-center self-stretch">
        <div className="container-xs flex flex-col gap-6 mdx:px-5">
          <div className="flex flex-col items-start">
            <Heading
              size="headings"
              as="h1"
              className="text-[20px] font-semibold text-[#000000]-900"
            >
              <span className="text-[#000000]-900">Hallo&nbsp;</span>
              <span className="text-cyan-a400">
                &#123;Promotervorname Name&#125;
              </span>
              , willkommen in deinem Empfehlungsportal
            </Heading>
            <Heading
              size="headingxs"
              as="h2"
              className="text-[16px] font-medium text-light_blue-a700"
            >
              <span>Hier kannst Du&nbsp;</span>
              <span>&#123;NutzerVorname Nachname&#125;</span>
              <span>&nbsp;weiterempfehlen und gleichzeitig profitieren!</span>
            </Heading>
          </div>

          <motion.div
            className="relative p-1 rounded-[15px] overflow-hidden"
            style={{
              background: "linear-gradient(135deg, #0084F7, #00DEF7)",
              backgroundSize: "400% 400%",
            }}
            animate={{
              backgroundPosition: ["0% 0%", "100% 100%", "0% 0%"],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "linear",
            }}
          >
            <div className="bonusrow_border rounded-[14px] bg-[#fafafa] p-4 pb-10">
              <div className="mt-2 flex flex-col items-start">
                <Heading
                  size="headings"
                  as="h3"
                  className="text-[20px] font-semibold text-[#000000]-900"
                >
                  <span className="text-[#000000]-900">
                    Sichere dir zusätzlichen&nbsp;
                  </span>
                  <span className="text-cyan-a400">Extra Bonus</span> nach
                  Anzahl erfolgreicher Empfehlungen
                </Heading>
                <div className="relative mt-3.5 h-[32px] self-stretch">
                  <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1 items-center rounded-[12px] bg-light_blue-a700_19 mdx:relative mdx:flex-col">
                    <div className="h-[24px] w-[36%] rounded-bl-[12px] rounded-tl-[12px] bg-light_blue-a700 mdx:px-5" />
                    <div className="flex flex-1 justify-center px-14 mdx:self-stretch mdx:px-5 smx:flex-col">
                      <Text
                        as="p"
                        className="text-[14px] !font-bold text-[#000000]-900"
                      >
                        <span className="text-[#000000]-900">
                          Nur noch&nbsp;
                        </span>
                        <span className="text-light_blue-a700">
                          3 Empfehlungen
                        </span>
                        <span className="text-[#000000]-900">
                          &nbsp;bis “Playstation 5”
                        </span>
                      </Text>
                      <div className="flex flex-1 flex-wrap justify-between gap-5 smx:self-stretch">
                        <Heading
                          size="headingxs"
                          as="h4"
                          className="relative text-shadow-ts ml-[132px] flex h-[20px] w-[20px] items-center justify-center rounded-[10px] border-[3px] border-solid border-[#ffffff] bg-light_blue-a700 text-center !text-[12px] font-semibold !text-[#ffffff]"
                        >
                          6
                          <div
                            className="absolute top-12 right-[-57px] h-[60px] w-auto flex flex-col items-center justify-center gap-0 mt-5"
                            style={{ zIndex: 99 }}
                          >
                            <Img
                              src="/images3/img_black_iphone_png_image.png"
                              alt="Iphone Image"
                              className="h-[100px] w-auto self-center object-contain"
                            />
                            <Button
                              color="blue_gray_100"
                              shape="round"
                              rightIcon={
                                <div className="flex h-[14px] w-[14px] items-center justify-center">
                                  <Img
                                    src="/images3/img_inbox.svg"
                                    alt="Inbox"
                                    className="mb-1 h-[14px] w-[14px]"
                                  />
                                </div>
                              }
                              className="relative mt-[-10px] text-[16px] min-w-[114px] gap-1.5 self-end rounded-[5px] px-2.5 font-semibold"
                            >
                              Playstation 5
                            </Button>
                          </div>
                        </Heading>
                        <Heading
                          size="headingxs"
                          as="h5"
                          className="relative text-shadow-ts flex h-[20px] w-[20px] items-center justify-center rounded-[10px] border-[3px] border-solid border-[#ffffff] bg-light_blue-a700 text-center !text-[12px] font-semibold !text-[#ffffff]"
                        >
                          8
                          <div
                            className="absolute top-12 right-[-57px] h-[100px] w-auto flex flex-col items-center justify-center gap-2"
                            style={{ zIndex: 99 }}
                          >
                            <Img
                              src="/images3/img_black_iphone_png_image_96x84.png"
                              alt="Iphone Image Small"
                              className="relative h-[96px] w-auto object-contain"
                            />
                            <Button
                              color="blue_gray_100"
                              shape="round"
                              rightIcon={
                                <div className="flex h-[14px] w-[14px] items-center justify-center">
                                  <Img
                                    src="/images3/img_inbox.svg"
                                    alt="Inbox"
                                    className="mb-0.5 h-[14px] w-[14px]"
                                  />
                                </div>
                              }
                              className="relative mt-[-15px] text-[16px] min-w-[116px] gap-1.5 rounded-[5px] px-2.5 py-2 font-semibold"
                            >
                              iPhone 15 Pro
                            </Button>
                          </div>
                        </Heading>
                      </div>
                    </div>
                  </div>
                  <Button
                    color="cyan_A400"
                    size="md"
                    shape="circle"
                    className="absolute bottom-0 left-[35%] top-0 my-auto w-[32px] rounded-[16px] border-[3px] border-solid border-[#ffffff] px-1"
                  >
                    <div className="relative">
                      <div className="absolute inset-0 bg-[#0080ff]/20 blur-3xl" />
                      <Img src="/images3/img_settings.svg" />
                      <div className="  flex flex-1 flex-col items-center justify-center absolute top-10 left-[-65px]">
                        <Img
                          src="/images3/img_111851_sp880_ai.png"
                          alt="Bonus Image"
                          className=" h-[108px] w-[108px] object-cover mdx:mr-0"
                        />
                        <Button
                          color="light_blue_A700"
                          shape="round"
                          className="relative z-20 -mt-6 min-w-[134px] rounded-md bg-[#0080ff] px-4 py-2 text-sm font-normal text-white transition-all hover:bg-[#0066cc] hover:shadow-lg before:absolute before:inset-0 before:-z-10 before:block before:rounded-md before:bg-[#0080ff]/50 before:blur-lg"
                        >
                          Jetzt Bonus sichern
                        </Button>
                        <div className="absolute inset-0 -z-10 bg-gradient-to-b from-[#66b3ff]/20 via-[#0080ff]/10 to-transparent blur-2xl" />{" "}
                      </div>
                    </div>
                  </Button>
                </div>
                <div className="mx-3 flex items-center self-stretch mdx:mx-0 mdx:flex-col">
                  <div className="flex flex-1 flex-col items-end pl-14 pr-44 mdx:self-stretch mdx:px-5"></div>
                  <div className="flex w-[44%] items-center mdx:w-full smx:flex-col">
                    <div className="relative h-[124px] flex-1 px-[46px] mdx:px-5 smx:w-full smx:flex-none smx:self-stretch">
                      <div className="absolute left-0 right-0 top-1.5 m-auto flex flex-1 items-start justify-end">
                        <Img
                          src="/images3/img_morflax_studio_5.png"
                          alt="Studio Image"
                          className="relative ml-[-26px] mt-3.5 h-[54px] w-[18%] object-contain opacity-50"
                        />
                      </div>
                      <Img
                        src="/images3/img_morflax_studio_7.png"
                        alt="Studio Image 7"
                        className="absolute right-[34%] top-0 m-auto h-[74px] w-[38%] object-contain opacity-50"
                      />
                      <div className="absolute bottom-px left-0 right-0 m-auto flex items-center justify-center">
                        <Img
                          src="/images3/img_morflax_studio_5_58x66.png"
                          alt="Playstation Image"
                          className="h-[58px] object-contain opacity-50"
                        />
                      </div>
                    </div>
                    <div className="relative h-[120px] w-[34%] content-center mdx:h-auto smx:w-full">
                      <div className="mx-auto flex-1">
                        <div className="flex flex-col items-end">
                          <Img
                            src="/images3/img_morflax_studio_5_28x30.png"
                            alt="Studio Image Small"
                            className="mr-4 h-[28px] object-cover mdx:mr-0"
                          />
                          <div className="relative mt-[-18px] flex items-center justify-end self-stretch">
                            <div className="flex flex-1 items-center justify-end">
                              <Img
                                src="/images3/img_morflax_studio_7.png"
                                alt="Studio Image 7 Small"
                                className="h-[62px] w-[34%] self-end object-contain"
                              />
                            </div>
                            <Img
                              src="/images3/img_morflax_studio_5.png"
                              alt="Studio Image Small 2"
                              className="relative mb-1 ml-[-22px] h-[46px] w-[22%] self-end object-contain"
                            />
                          </div>
                        </div>
                        <div className="relative mx-2 mt-[-22px] flex flex-col items-end mdx:mx-0">
                          <Img
                            src="/images3/img_morflax_studio_5_58x66.png"
                            alt="Iphone Image Button"
                            className="mr-9 h-[36px] object-cover mdx:mr-0"
                          />
                        </div>
                      </div>
                      <Img
                        src="/images3/img_morflax_studio_6.png"
                        alt="Studio Image 6"
                        className="absolute left-[36%] top-[7px] m-auto h-[34px] object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}
