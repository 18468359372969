import React from "react";
import { Heading } from "./components";

export default function FooterSection() {
  return (
    <>
      {/* footer section */}
      <div className="mt-6 self-stretch">
        <div className="flex flex-col items-center gap-[22px]">
          <div className="h-px w-full self-stretch bg-blue_gray-100" />
          <div className="container-xs mdx:px-5">
            <div className="flex items-center justify-center smx:flex-col">
              <Heading
                as="h2"
                className="text-[14px] font-medium text-[#000000]-900"
              >
                © Copyright EinGuterTipp
              </Heading>
              <div className="flex flex-1 flex-wrap justify-end gap-[31px] smx:self-stretch">
                <Heading
                  as="h3"
                  className="text-[14px] font-medium text-[#000000]-900"
                >
                  Teilnahmebedingungen
                </Heading>
                <Heading
                  as="h4"
                  className="text-[14px] font-medium text-[#000000]-900"
                >
                  Impressum
                </Heading>
                <a href="Datenschutz" target="_blank" rel="noreferrer">
                  <Heading
                    as="h5"
                    className="text-[14px] font-medium text-[#000000]-900"
                  >
                    Datenschutz
                  </Heading>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
